import React, { useState, useEffect } from "react";
import { Button, Dimmer, Grid, Loader, Modal, Image } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchImages } from "../../actions";
import ImageBlock from "./imageBlock";

const ImageModal = ({ modal_open, update_modal_open, image_selected }) => {
  const dispatch = useDispatch();
  const [loading, update_loading] = useState(true);
  const images = useSelector((state) => state.images);

  useEffect(() => {
    dispatch(fetchImages());
    update_loading(false);
  }, []);

  function returnImages() {
    return <ImageBlock images={images} image_selected={image_selected} />;
  }

  return (
    <>
      <Modal
        size={"large"}
        open={modal_open}
        onClose={() => update_modal_open(false)}
      >
        <Modal.Header>Select Image to add</Modal.Header>
        {loading ? (
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
        ) : (
          <Modal.Content>{returnImages()}</Modal.Content>
        )}

        <Modal.Actions>
          <Button secondary onClick={() => update_modal_open(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ImageModal;
