import React from 'react'
import { Header, Modal, Progress } from 'semantic-ui-react'

const UploadModal = props => (
  <Modal
    basic size='tiny'
    open={props.uploadModalOpen}
    >
    <Header icon='upload' content='Uploadling file' />
    <Modal.Content>
    </Modal.Content>
    <Modal.Actions>
      <div>
        <Progress
          id="progress_bar"
          percent={20}
          indicating
          />
      </div>
    </Modal.Actions>
  </Modal>
)

export default UploadModal
