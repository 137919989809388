import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";

import { addImage } from "../../actions";
import { processImage } from "../../graphql/queries";

import { Segment } from "semantic-ui-react";
import UploadModal from "../../libs/UploadModal";
import { s3Upload } from "../../libs/awsLib";
import s3config from "../../s3config";
import shorthash from "shorthash";
import moment from "moment";

const UploadImageSection = () => {
  const dispatch = useDispatch();
  const [uploadModalOpen, update_uploadModalOpen] = useState(false);
  const timestamp = moment().unix();

  async function upload(event) {
    try {
      console.log("running upload function");
      update_uploadModalOpen(true);
      const file = event.target.files[0];
      console.log(file);

      const newFile = await s3Upload(file);
      console.log("newFile");
      console.log(newFile);
      const public_url = s3config.S3.URL + s3config.S3.EXT + newFile;
      console.log("public_url");
      console.log(public_url);
      const hash = shorthash.unique(public_url);

      const params_to_send = JSON.stringify({
        params: {},
        size: 400,
        url: public_url,
      });
      console.log("params_to_send");
      console.log(params_to_send);
      const response = await API.graphql(
        graphqlOperation(processImage, {
          image: JSON.stringify({
            params: {},
            size: 400,
            url: public_url,
          }),
        })
      );
      console.log("response");
      console.log(response);

      const parsed_response = JSON.parse(response.data.processImage);
      const parsed_response_body = JSON.parse(parsed_response.body);
      const new_url = JSON.parse(parsed_response_body);
      const params = {
        image_url: new_url,
        original_image: public_url,
        hash: hash,
        version_id: shorthash.unique(new_url),
      };
      dispatch(addImage(params));
      update_uploadModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Segment secondary>
      <div key={timestamp} style={{ textAlign: "center" }}>
        {/* <h5 style={{ marginBottom: 5 }}>Upload image:</h5> */}
        <label for={"file:" + timestamp} className="ui icon button teal">
          <i className="file icon"></i>
          Upload Images
        </label>
        <input
          type="file"
          id={"file:" + timestamp}
          accept="image/png, image/jpeg"
          onChange={upload}
          style={{ display: "none" }}
          key={timestamp}
        />
        <UploadModal uploadModalOpen={uploadModalOpen} />
      </div>
    </Segment>
  );
};

export default UploadImageSection;
