import React from "react";
import { Card, Dimmer, Grid, Loader, Tab, Image } from "semantic-ui-react";

import UploadImageSection from "./UploadImage";

const ImageBlock = ({ images, image_selected }) => {
  return (
    <div>
      <Grid colums={4}>
        {images.map((image) => (
          <Grid.Column width={3}>
            <Card>
              <Image
                syle={{
                  border: "none",
                  boxShadow: "none",
                  borderWidth: 0,
                  borderColor: "#ffffff",
                  borderBottom: "none",
                }}
                src={image.image_url}
                key={image.id}
                onClick={() => image_selected(image)}
              />
            </Card>
          </Grid.Column>
        ))}
      </Grid>
    </div>
  );
};

export default ImageBlock;
